import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useRouteMatch } from "react-router-dom";
import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import "jspdf-autotable";

import { CustomTheme } from "../../../../types/customTheme";
import WithLoading from "../../../../utils/WithLoading";
import MessageInfoNode from "./MessageInfoNode";
import MessageHeader from "./MessageHeader";
import CreateNewMessage from "./CreateNewMessage";
import {
  createNewBannerInfo,
  createNewMessageInfo,
  deleteBannerInfo,
  sendNotification,
  updateBannerInfo,
  updateMessageInfo,
} from "../../../../services/customerApp/customerDetails";
import {
  SUCCESSFULLY_CREATED,
  SUCCESSFULLY_UPDATED,
} from "../../../../utils/consts";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import MessageCreateAndUpdateModal from "./MessageCreateAndUpdateModal";
import MessagePreview from "./MessagePreview";
import moment from "moment";
import AlertDialogDefault from "../../../../components/alerts/AlertDialogDefault";

export interface CustomerNodeProps {
  isLoading: any;
  messageNode: any;
  isOwner: any;
  programId: any;
}

const useStyles = makeStyles((theme: CustomTheme) => createStyles({}));

const MessageNode: React.FunctionComponent<CustomerNodeProps> = ({
  isLoading,
  messageNode,
  isOwner,
  programId,
}) => {
  const [messageList, setMessageList] = useState<any>([]);
  const [error, setError] = useState("");
  const [isOpenMessageAddEditModal, setIsOpenMessageAddEditModal] =
    useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoadingSubmitButton, setIsLoadingSubmitButton] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [message, setMessage] = useState("");
  const [img, setImg] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [actionUrl, setActionUrl] = useState("");
  const [titleInitial, setTitleInitial] = useState("");
  const [subTitleInitial, setSubTitleInitial] = useState("");
  const [messageInitial, setMessageInitial] = useState("");
  const [imgInitial, setImgInitial] = useState("");
  const [selectedDateInitial, setSelectedDateInitial] = useState(new Date());
  const [actionUrlInitial, setActionUrlInitial] = useState("");
  const [isError, setIsError] = useState(false);
  const [success, setSuccess] = useState("");
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [previewMessageDetails, setPreviewMessageDetails] = useState({});
  const [status, setStatus] = useState("");

  const match: any = useRouteMatch();

  useEffect(() => {
    if (!_.isEmpty(messageNode)) {
      setMessageList(messageNode);
    }
  }, [messageNode]);

  const handleOpenMessageAddModal = () => {
    setIsOpenMessageAddEditModal(true);
  };

  const handleReset = () => {
    setIsError(false);
    setIsEdit(false);
    setIsOpenMessageAddEditModal(false);
    setTitle("");
    setSubTitle("");
    setMessage("");
    setImg("");
    setActionUrl("");

    setTitleInitial("");
    setSubTitleInitial("");
    setMessageInitial("");
    setImgInitial("");
    setActionUrlInitial("");
  };

  const handleCreateMessage = async () => {
    try {
      const formData = {
        title: title,
        subtitle: subTitle,
        message: message,
        img: img,
        actionUrl,
        // expire: selectedDate,
        programId,
      };
      const res = await createNewMessageInfo(match.params.locationId, formData);
      const cloneBannerList = _.cloneDeep(messageList);
      cloneBannerList.unshift(res.data.data); // Add new message at the top
      setMessageList(cloneBannerList);
      setIsLoadingSubmitButton(false);
      setIsEdit(false);
      setIsOpenMessageAddEditModal(false);
      setTitle("");
      setSubTitle("");
      setMessage("");
      setImg("");
      setSelectedDate(new Date());
      setActionUrl("");
      setSuccess(SUCCESSFULLY_CREATED);
    } catch (error) {
      setIsError(true);
      setIsLoadingSubmitButton(false);
    }
  };

  const handleUpdateMessage = async () => {
    try {
      const formData = {
        title: title,
        subtitle: subTitle,
        message: message,
        img: img,
        actionUrl,
        // expire: selectedDate,
        programId,
      };
      console.log("Asdasdasfsa", messageId);
      const res = await updateMessageInfo(
        match.params.locationId,
        messageId,
        formData,
      );
      const cloneMessageList = _.cloneDeep(messageList);
      const {
        title: resTitle,
        subtitle: resSubTitle,
        message: resMessage,
        img: resImg,
        actionUrl: resActionUrl,
        // expire: resSelectedDate,
      } = res.data.data;
      cloneMessageList.map((data: any) => {
        if (data.id === messageId) {
          data["title"] = resTitle;
          data["subtitle"] = resSubTitle;
          data["message"] = resMessage;
          data["img"] = resImg;
          data["actionUrl"] = resActionUrl;
          // data["expire"] = resSelectedDate;
        }
      });
      setMessageList(cloneMessageList);
      setIsLoadingSubmitButton(false);
      setIsEdit(false);

      setTitle("");
      setSubTitle("");
      setMessage("");
      setImg("");
      setSelectedDate(new Date());
      setActionUrl("");
      setIsOpenMessageAddEditModal(false);
      setSuccess(SUCCESSFULLY_UPDATED);
    } catch (error) {
      setIsError(true);
      setIsLoadingSubmitButton(false);
    }
  };

  const handleSubmitData = () => {
    if (
      (!title && !subTitle && !message && !img && !actionUrl) ||
      (!img.includes("http") && img)
    ) {
      setIsError(true);
    } else {
      setIsLoadingSubmitButton(true);
      setIsError(false);
      if (isEdit) {
        handleUpdateMessage();
      } else {
        handleCreateMessage();
      }
    }
  };

  const handleEditMessageDetails = (messageDetails: any) => {
    const { id, title, subtitle, message, img, actionUrl, expire, status } =
      messageDetails;

    setStatus(status);
    setMessageId(id);
    setTitle(title);
    setSubTitle(subtitle);
    setMessage(message);
    setImg(img);
    setSelectedDate(expire);
    setIsEdit(true);
    setIsOpenMessageAddEditModal(true);
    setActionUrl(actionUrl);
    setTitleInitial(title);
    setSubTitleInitial(subtitle);
    setMessageInitial(message);
    setImgInitial(img);
    setSelectedDateInitial(expire);
    setActionUrlInitial(actionUrl);
  };

  const handleDeleteBannerDetails = async (bannerId: any) => {
    try {
      const res = await deleteBannerInfo(match.params.locationId, bannerId);
      const cloneBannerList = _.cloneDeep(messageList);
      const filterCloneBannerList = cloneBannerList.filter(
        (data: any) => data.bannerId !== res.data.data.bannerId,
      );
      setMessageList(filterCloneBannerList);
      setIsLoadingButton(false);
      setIsEdit(false);
      setIsOpenMessageAddEditModal(false);
    } catch (error) {
      setIsError(true);
    }
  };

  const [messageId, setMessageId] = useState("");

  const handleSendNotificationNew = async (id: any, cloneBannerList: any) => {
    try {
      setMessageId(id);
      setIsLoadingButton(true);
      const res = await sendNotification(match.params.locationId, id);

      cloneBannerList.map(
        (data: any) => data.id === id && (data.status = "sent"),
      );
      setStatus("sent");
      setIsEdit(true);
      setMessageList(cloneBannerList);
      setIsLoadingButton(false);
      setIsOpen(false);
      setIsOpenMessageAddEditModal(false);
      setSuccess("Notification sent successfully.");
    } catch (error) {
      setIsError(true);
      setIsLoadingButton(false);
    }
  };
  const handleSendNotification = async (messageList: any) => {
    try {
      setMessageId(messageId);
      setIsLoadingButton(true);
      const res = await sendNotification(match.params.locationId, messageId);
      const cloneBannerList = _.cloneDeep(messageList);

      cloneBannerList.map(
        (data: any) => data.id === messageId && (data.status = "sent"),
      );
      setStatus("sent");
      setMessageList(cloneBannerList);
      setIsLoadingButton(false);
      setIsOpen(false);
      setIsOpenMessageAddEditModal(false);
      setSuccess("Notification sent successfully.");
    } catch (error) {
      setIsError(true);
      setIsLoadingButton(false);
    }
  };
  // Set the error message

  const handleUpdateMessageNotification = async () => {
    try {
      const formData = {
        title: title,
        subtitle: subTitle,
        message: message,
        img: img,
        actionUrl,
        // expire: selectedDate,
        programId,
      };
      const res = await updateMessageInfo(
        match.params.locationId,
        messageId,
        formData,
      );
      const cloneMessageList = _.cloneDeep(messageList);
      const {
        title: resTitle,
        subtitle: resSubTitle,
        message: resMessage,
        img: resImg,
        actionUrl: resActionUrl,
        // expire: resSelectedDate,
      } = res.data.data;
      cloneMessageList.map((data: any) => {
        if (data.id === messageId) {
          data["title"] = resTitle;
          data["subtitle"] = resSubTitle;
          data["message"] = resMessage;
          data["img"] = resImg;
          data["actionUrl"] = resActionUrl;
          // data["expire"] = resSelectedDate;

          setTitleInitial(resTitle);
          setSubTitleInitial(resSubTitle);
          setMessageInitial(resMessage);
          setImgInitial(resImg);
          setActionUrlInitial(resActionUrl);
          // setSelectedDateInitial(resSelectedDate)
        }
      });
      setMessageList(cloneMessageList);
      handleSendNotification(cloneMessageList);
    } catch (error) {
      setIsError(true);
      setIsLoadingSubmitButton(false);
    }
  };

  const handleCreateMessageNotification = async () => {
    try {
      const formData = {
        title: title,
        subtitle: subTitle,
        message: message,
        img: img,
        actionUrl,
        // expire: selectedDate,
        programId,
      };
      const res = await createNewMessageInfo(match.params.locationId, formData);
      const {
        title: resTitle,
        subtitle: resSubTitle,
        message: resMessage,
        img: resImg,
        actionUrl: resActionUrl,
        // expire: resSelectedDate,
      } = res.data.data;
      const cloneBannerList = _.cloneDeep(messageList);
      cloneBannerList.unshift(res.data.data); // Add new message at the top
      cloneBannerList.push(res.data.data);
      setTitleInitial(resTitle);
      setSubTitleInitial(resSubTitle);
      setMessageInitial(resMessage);
      setImgInitial(resImg);
      setActionUrlInitial(resActionUrl);
      // setSelectedDateInitial(resSelectedDate)
      setMessageList(cloneBannerList);
      setIsLoadingSubmitButton(false);

      setSuccess(SUCCESSFULLY_CREATED);
      handleSendNotificationNew(res.data.data.id, cloneBannerList);
    } catch (error) {
      setIsError(true);
      setIsLoadingSubmitButton(false);
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleOpenNotificationConfirmation = () => {
    setIsOpen(true);
  };

  const handleSendPublishNotification = async () => {
    if (!img.includes("http") && img) {
      setIsError(true);
    } else {
      setIsLoadingButton(true);
      setIsError(false);
      if (isEdit) {
        if (
          titleInitial !== title ||
          subTitleInitial !== subTitle ||
          message !== messageInitial ||
          img !== imgInitial ||
          moment(selectedDate).format("YYYY-MM-DD") !==
            moment(selectedDateInitial).format("YYYY-MM-DD") ||
          actionUrl !== actionUrlInitial
        ) {
          handleUpdateMessageNotification();
        } else {
          handleSendNotification(messageList);
        }
      } else {
        handleCreateMessageNotification();
      }
    }
  };

  const handleEdit = () => {};

  const handleOpenPreview = (messageObj: any) => {
    setPreviewMessageDetails(messageObj);
    setIsOpenPreview(true);
  };

  return (
    <>
      <CreateNewMessage
        handleOpenMessageAddModal={handleOpenMessageAddModal}
        isOwner={isOwner}
      />
      {messageList && <MessageHeader />}
      <Grid container>
        {messageList ? (
          messageList.map((messageInfo: any) => (
            <Grid
              item
              xs={12}
              key={messageInfo.id}
              style={{ marginTop: "8px" }}
            >
              <MessageInfoNode
                message={messageInfo}
                handleDeleteBannerDetails={handleDeleteBannerDetails}
                isOwner={isOwner}
                handleSendPublishNotification={handleSendPublishNotification}
                handleOpenPreview={handleOpenPreview}
                isLoadingButton={isLoadingButton}
                messageId={messageId}
                handleEditMessageDetails={handleEditMessageDetails}
              />
            </Grid>
          ))
        ) : (
          <Typography style={{ marginTop: "12%" }} variant="h5">
            No Messages
          </Typography>
        )}
      </Grid>

      <MessageCreateAndUpdateModal
        isOpen={isOpenMessageAddEditModal}
        setIsOpen={setIsOpenMessageAddEditModal}
        isEdit={isEdit}
        handleReset={handleReset}
        isLoadingButton={isLoadingButton}
        handleSubmitData={handleSubmitData}
        title={title}
        subTitle={subTitle}
        message={message}
        img={img}
        isError={isError}
        setIsError={setIsError}
        setTitle={setTitle}
        setSubTitle={setSubTitle}
        setMessage={setMessage}
        setImg={setImg}
        actionUrl={actionUrl}
        setActionUrl={setActionUrl}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        handleSendPublishNotification={handleOpenNotificationConfirmation}
        isLoadingSubmitButton={isLoadingSubmitButton}
        titleInitial={titleInitial}
        subTitleInitial={subTitleInitial}
        messageInitial={messageInitial}
        imgInitial={imgInitial}
        selectedDateInitial={selectedDateInitial}
        actionUrlInitial={actionUrlInitial}
        status={status}
      />

      <MessagePreview
        isOpen={isOpenPreview}
        setIsOpen={setIsOpenPreview}
        messageDetails={previewMessageDetails}
      />

      <DefaultAlert
        open={!!success}
        handleClose={() => setSuccess("")}
        message={success}
        severity={"success"}
      />

      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />

      <AlertDialogDefault
        open={isOpen}
        confirmAction={() => handleSendPublishNotification()}
        cancelAction={() => setIsOpen(false)}
        title="Confirm Publish"
        desc="Are you sure you want to publish this message? Once published, it will be sent to all users and cannot be edited. Do you want to proceed?"
        severity="warning"
        confirmLabel="Confirm & Publish"
        cancelLabel="Cancel"
        color="secondary"
        disableBackdropClick
        isLoading={isLoadingButton}
      />
    </>
  );
};

export default WithLoading(MessageNode);
