import React, { useState } from "react";
import {
  createStyles,
  FormControlLabel,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import _ from "lodash";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import CardCommon from "../../../../components/card/CardCommon";
import { Autocomplete } from "@material-ui/lab";
import SwitchCommon from "../../../../components/switches/SwitchCommon";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import { buttonColors } from "../../../../utils/enum";
import { useRouteMatch } from "react-router-dom";

export interface StockConfigurationNodeProps {
  locationSelectorList: any;
  setSelectedDepartmentObj: any;
  selectedDepartmentObj: any;
  setIsActive: any;
  isActive: any;
  stockConfiguration: any;
  handleGetMasterLocationSpecificLocation: any;
  selectedDepartmentObjInitial: any;
  isActiveInitial: any;
  setIsEnableMasterLocation: any;
  isEnableMasterLocation: any;
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    titleColor: {
      color: theme.palette.custom.orange.contrastText,
    },
    autoComplete: {
      marginTop: "4px",
      "& .MuiAutocomplete-input": {
        color: theme.palette.custom.orange.contrastText,
      },
      "& .MuiInputBase-root": {
        color: "inherit",
        backgroundColor: theme.palette.background.entity_background,
        borderRadius: 10,
      },
      [`& fieldset`]: {
        borderRadius: 10,
        border: "none",
        color: theme.palette.custom.orange.contrastText,
      },
    },
  }),
);

/**
 * Component: StockConfigurationNode
 *
 * Description:
 * This component represents a node in the Stock Configuration page.
 * It provides controls for managing stock-related configurations, such as enabling/disabling stock,
 * enabling/disabling master location, selecting the department, and saving changes.
 **/

const StockConfigurationNode: React.FunctionComponent<
  StockConfigurationNodeProps
> = ({
  locationSelectorList,
  setSelectedDepartmentObj,
  selectedDepartmentObj,
  setIsActive,
  isActive,
  stockConfiguration,
  handleGetMasterLocationSpecificLocation,
  selectedDepartmentObjInitial,
  isActiveInitial,
  setIsEnableMasterLocation,
  isEnableMasterLocation,
}) => {
  const [open, setOpen] = useState(false);

  const classes = useStyles();
  const match: any = useRouteMatch();

  /**
   * Function: handleChangeMasterLocation
   *
   * Description:
   * This function handles the change event when selecting a master location in the Autocomplete component.
   * It checks if the selected master location's ID matches the current location ID. If so, it disables the master location
   * and sets a default value for the selected department. Otherwise, it sets the selected department based on the
   * chosen master location.
   **/
  const handleChangeMasterLocation = (e: any, masterLocationData: any) => {
    // Check if the selected master location's ID matches the current location ID
    if (masterLocationData.id === match.params.locationId) {
      // Disable master location and set a default value for the selected department
      setIsEnableMasterLocation(false);
      setSelectedDepartmentObj({
        id: match.params.locationId,
        label: "Master Location Not Required",
      });
    } else {
      // Set the selected department based on the chosen master location
      setSelectedDepartmentObj(masterLocationData);
    }
  };

  /**
   * Function: handleBackgroundColor
   *
   * Description:
   * This function determines the background color based on the presence or absence of stock configuration.
   * If the stock configuration is not empty, it returns 'green', indicating that stock is enabled.
   * Otherwise, it returns 'red', indicating that stock is not enabled.
   **/
  const handleBackgroundColor = () => {
    // Check if the stock configuration is not empty
    if (!_.isEmpty(stockConfiguration) && stockConfiguration.active) {
      // Return 'green' if stock is enabled
      return "green";
    } else {
      // Return 'red' if stock is not enabled
      return "red";
    }
  };

  /**
   * Function: handleActivateStock
   *
   * Description:
   * This function handles the activation or deactivation of stock based on the user's interaction with the switch.
   * It extracts the 'checked' property from the event object and sets the 'isActive' state accordingly.
   **/
  const handleActivateStock = (e: any) => {
    // Extract the 'checked' property from the event object
    const { checked } = e.target;

    // Set the 'isActive' state based on the user's interaction
    setIsActive(checked);
  };

  /**
   * Function: handleEnableMasterLocation
   *
   * Description:
   * This function handles the enabling or disabling of the Master Location based on the user's interaction with the switch.
   * It extracts the 'checked' property from the event object and sets the 'isEnableMasterLocation' state accordingly.
   * If Master Location is being disabled, it sets the 'selectedDepartmentObj' state to indicate that Master Location is not required.
   **/
  const handleEnableMasterLocation = (e: any) => {
    // Extract the 'checked' property from the event object
    const { checked } = e.target;

    // Set the 'isEnableMasterLocation' state based on the user's interaction
    setIsEnableMasterLocation(checked);

    // If Master Location is being disabled, update the 'selectedDepartmentObj' state
    if (!checked) {
      setSelectedDepartmentObj({
        id: match.params.locationId,
        label: "Master Location Not Required",
      });
    }
  };

  /**
   * Function: handleSubmit
   *
   * Description:
   * This function is triggered when the user clicks the "Save" button. It calls the 'handleGetMasterLocationSpecificLocation' function,
   * passing the 'id' of the selected department as an argument.
   **/
  const handleSubmit = () => {
    handleGetMasterLocationSpecificLocation(selectedDepartmentObj.id);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={5} xl={4}>
          <CardCommon color={handleBackgroundColor()}>
            <div style={{ marginTop: "20px" }}></div>
            <div style={{ marginRight: "40px" }}>
              <CardCommon
                backgroundColor={"entity_highlight_background"}
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0px 20px 20px 20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    margin: "12px 4px",
                    width: "100%",
                  }}
                >
                  <FormControlLabel
                    style={{ marginRight: "0px" }}
                    control={<SwitchCommon />}
                    checked={isActive}
                    onChange={handleActivateStock}
                    label="Enable Stock"
                    labelPlacement="start"
                  />

                  <FormControlLabel
                    style={{ marginRight: "0px" }}
                    control={<SwitchCommon />}
                    checked={isEnableMasterLocation}
                    onChange={handleEnableMasterLocation}
                    label="Enable Master Location"
                    labelPlacement="start"
                  />

                  {/* <ButtonCommon
                  onClick={handleSubmit}
                  variant="contained"
                  style={{
                    fontSize: 11,
                    width: "138px",
                    marginRight: "12px",
                  }}
                  disabled={
                    _.isEmpty(selectedDepartmentObj) ||
                    (selectedDepartmentObjInitial.id ===
                      selectedDepartmentObj.id &&
                      isActive === isActiveInitial)
                  }
                  color={
                    !_.isEmpty(stockConfiguration)
                      ? buttonColors.UPDATE_BUTTON_COLOR
                      : buttonColors.CREATE_BUTTON_COLOR
                  }
                >
                  {"Active Stock"}
                </ButtonCommon> */}
                </div>
              </CardCommon>
            </div>
            <div style={{ margin: "20px" }}>
              <Autocomplete
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                size={"small"}
                id="locationSelectGlobal"
                color="inherit"
                options={locationSelectorList}
                value={selectedDepartmentObj}
                getOptionLabel={(option: any) => option.label || ""}
                disableClearable
                disabled={!isEnableMasterLocation}
                onChange={handleChangeMasterLocation}
                classes={{ root: classes.autoComplete }}
                popupIcon={<ArrowDropDownIcon color={"inherit"} />}
                renderOption={(props: any) => {
                  return (
                    <Grid
                      container
                      {...props}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        zIndex: 0,
                        height: "20px",
                        marginBottom: "4px",
                      }}
                    >
                      <Grid item xs={12}>
                        <Typography
                          variant="body2"
                          className={classes.titleColor}
                        >
                          {props.label}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                }}
                renderInput={(params: any) => (
                  <TextField
                    color="inherit"
                    {...params}
                    label=""
                    placeholder="Select Department"
                    variant="outlined"
                  />
                )}
              />
            </div>
            <div style={{ marginRight: "40px" }}>
              <CardCommon
                backgroundColor={"entity_highlight_background"}
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0px 20px 20px 20px",
                  border: "none",
                }}
              >
                <ButtonCommon
                  onClick={handleSubmit}
                  variant="contained"
                  style={{
                    fontSize: 11,
                    width: "100%",
                  }}
                  disabled={
                    _.isEmpty(selectedDepartmentObj) ||
                    (selectedDepartmentObjInitial.id ===
                      selectedDepartmentObj.id &&
                      isActive === isActiveInitial) ||
                    (isEnableMasterLocation &&
                      selectedDepartmentObj.id === match.params.locationId)
                  }
                  color={
                    !_.isEmpty(stockConfiguration)
                      ? buttonColors.UPDATE_BUTTON_COLOR
                      : buttonColors.CREATE_BUTTON_COLOR
                  }
                >
                  {"Save"}
                </ButtonCommon>
              </CardCommon>
            </div>
          </CardCommon>
        </Grid>
      </Grid>
    </>
  );
};

// Apply the WithLoading higher-order component to the StockConfigurationNode component
export default StockConfigurationNode;
