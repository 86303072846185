import React from "react";
import _ from "lodash";
import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import "jspdf-autotable";

import { CustomTheme } from "../../../../types/customTheme";
import CardCommon from "../../../../components/card/CardCommon";

export interface CustomerNodeProps {}

const useStyles = makeStyles((theme: CustomTheme) => createStyles({}));

const MessageHeader: React.FunctionComponent<CustomerNodeProps> = ({}) => {
  return (
    <>
      <CardCommon backgroundColor={"table_header_background"}>
        <Grid container style={{ height: "32px", placeItems: "center" }}>
          <Grid
            item
            xs={1}
            style={{
              paddingLeft: "12px",
            }}
          >
            <Typography align="left"></Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography align="left">Title</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography align="left">Subtitle</Typography>
          </Grid>
          <Grid item xs={3}  style={{
              paddingRight: "90px",
            }}>
            <Typography align="right">Created</Typography>
          </Grid>
        </Grid>
      </CardCommon>
    </>
  );
};

export default MessageHeader;
