import React from "react";
import _ from "lodash";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from "@material-ui/core";
import "jspdf-autotable";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import EditIcon from "@material-ui/icons/Edit";

import CardCommon from "../../../../components/card/CardCommon";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import { convertDateTimeFormat } from "../../../../utils/ConvertDateTimeFormat";
import { buttonColors } from "../../../../utils/enum";
import { CustomTheme } from "../../../../types/customTheme";

export interface CustomerNodeProps {
  message: any;
  handleDeleteBannerDetails: any;
  isOwner: any;
  handleSendPublishNotification: any;
  handleOpenPreview: any;
  isLoadingButton: any;
  messageId: any;
  handleEditMessageDetails: any;
}

const MessageInfoNode: React.FunctionComponent<CustomerNodeProps> = ({
  message,
  handleDeleteBannerDetails,
  isOwner,
  handleSendPublishNotification,
  handleOpenPreview,
  isLoadingButton,
  messageId,
  handleEditMessageDetails,
}) => {
  const theme: CustomTheme = useTheme();
  return (
    <>
      <CardCommon backgroundColor={"entity_background"}>
        <Grid container style={{ minHeight: "40px", placeItems: "center" }}>
          <Grid
            item
            xs={1}
            style={{
              paddingLeft: "12px",
            }}
          >
            <Typography
              align="left"
              style={{ display: "flex", alignItems: "center" }}
            >
              {message.status === "sent" ? (
                <CheckCircleIcon
                  style={{ color: theme.palette.custom.green.main }}
                />
              ) : (
                "Draft"
              )}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              align="left"
              style={{ display: "flex", alignItems: "center", paddingRight: "8px" }}
            >
              {message.title}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              align="left"
              style={{ display: "flex", alignItems: "center" }}
            >
              {message.subtitle}
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              paddingRight: "12px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Typography align="right" style={{ marginRight: "12px" }}>
                {convertDateTimeFormat(message.created)}
              </Typography>
              <ButtonCommon
                style={{
                  fontSize: 11,
                  height: "36px",
                  marginTop: "8px",
                  marginBottom: "8px",
                }}
                variant="contained"
                color="yellow"
                size={"small"}
                // isLoadingPage={isLoadingButton && messageId === message.id}
                onClick={() => handleEditMessageDetails(message)}
              >
                <EditIcon />
              </ButtonCommon>
            </div>
          </Grid>
        </Grid>
      </CardCommon>
    </>
  );
};

export default MessageInfoNode;
