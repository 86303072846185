import { axiosClient } from "../client";

const queryString = require("query-string");

const CUSTOMER_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_CUSTOMER}/location/${locationId}/customers/list`;

const API_GET_LOYALTY_PROGRAM = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOYALTY}/location/${locationId}/getProgramInfo`;

const API_GET_REWARDS = (locationId, programId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOYALTY}/location/${locationId}/getRewards/${programId}`;

const API_GET_LOYALTY_STRATEGY = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOYALTY}/location/${locationId}/getStrategy`;

const API_CREATE_STRATEGY = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOYALTY}/location/${locationId}/addStrategy`;

const API_CREATE_REWARDS = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOYALTY}/location/${locationId}/addRewards`;

const API_UPDATE_REWARDS = (locationId, rewardId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOYALTY}/location/${locationId}/updateRewards/${rewardId}`;

const API_UPDATE_LOYALTY_PROGRAM = (locationId, programId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOYALTY}/location/${locationId}/program/${programId}`;

const API_UPDATE_STRATEGY_PROGRAM = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOYALTY}/location/${locationId}/updateStrategy`;

const API_UPDATE_STRATEGY_STATUS = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOYALTY}/location/${locationId}/activeStrategy`;

const API_UPDATE_LOCATION_PROGRAM = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOYALTY}/location/${locationId}/changeLocationProgram`;

const API_CREATE_BANNER_INFO = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOYALTY}/location/${locationId}/addBanner`;

const API_CREATE_MESSAGE_INFO = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOYALTY}/location/${locationId}/addMessage`;

const API_GET_BANNER_INFO = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOYALTY}/location/${locationId}/getBanner`;

const API_GET_MESSAGE_INFO = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOYALTY}/location/${locationId}/getMessage`;

const API_GET_API_KEY_INFO = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOYALTY}/location/${locationId}/getApiKeys`;

const API_CREATE_API_KEY_INFO = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOYALTY}/location/${locationId}/addApiKey`;

const API_UPDATE_API_KEY_INFO = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOYALTY}/location/${locationId}/updateApiKey`;

const API_UPDATE_BANNER_INFO = (locationId, bannerId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOYALTY}/location/${locationId}/bannerUpdate/${bannerId}`;

const API_UPDATE_MESSAGE_INFO = (locationId, messageId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOYALTY}/location/${locationId}/updateMessage/${messageId}`;

const API_DELETE_BANNER_INFO = (locationId, bannerId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOYALTY}/location/${locationId}/banner/${bannerId}`;

const API_SEND_NOTIFICATION = (locationId, messageId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOYALTY}/location/${locationId}/marketingNotification/${messageId}`;

export const fetchRewardsInfo = (locationId, programId) =>
  axiosClient({
    method: "GET",
    url: API_GET_REWARDS(locationId, programId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const fetchLoyaltyProgramInfo = (locationId) =>
  axiosClient({
    method: "GET",
    url: API_GET_LOYALTY_PROGRAM(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const fetchLoyaltyStrategyInfo = (locationId) =>
  axiosClient({
    method: "GET",
    url: API_GET_LOYALTY_STRATEGY(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const createNewStrategyInfo = (locationId, data) =>
  axiosClient({
    method: "POST",
    url: API_CREATE_STRATEGY(locationId),
    data,
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const createNewRewardsInfo = (locationId, data) =>
  axiosClient({
    method: "POST",
    url: API_CREATE_REWARDS(locationId),
    data,
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const updateRewardsInfo = (locationId, rewardId, data) =>
  axiosClient({
    method: "PUT",
    url: API_UPDATE_REWARDS(locationId, rewardId),
    data,
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const updateStrategyInfo = (locationId, data) =>
  axiosClient({
    method: "PUT",
    data,
    url: API_UPDATE_STRATEGY_PROGRAM(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const updateStrategyStatus = (locationId, data) =>
  axiosClient({
    method: "PUT",
    data,
    url: API_UPDATE_STRATEGY_STATUS(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const updateProgramStatus = (locationId, programId, data) =>
  axiosClient({
    method: "PUT",
    data,
    url: API_UPDATE_LOYALTY_PROGRAM(locationId, programId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const updateLocationProgramStatus = (locationId, programId, data) =>
  axiosClient({
    method: "PUT",
    data,
    url: API_UPDATE_LOYALTY_PROGRAM(locationId, programId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const updateLocationProgram = (locationId, data) =>
  axiosClient({
    method: "PUT",
    data,
    url: API_UPDATE_LOCATION_PROGRAM(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const fetchAllCustomerInfo = (
  token,
  locationId,
  size,
  currentPage,
  shortingMethod,
  shortingHeaderName,
  filterData,
) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: CUSTOMER_INFO_GET_ALL(locationId),
        query: {
          size: size,
          page: currentPage,
          sort: [shortingHeaderName, shortingMethod],
          where: filterData,
        },
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

export const fetchBannerInfo = (locationId) =>
  axiosClient({
    method: "GET",
    url: API_GET_BANNER_INFO(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const createNewBannerInfo = (locationId, data) =>
  axiosClient({
    method: "POST",
    url: API_CREATE_BANNER_INFO(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
    data,
  });

export const updateBannerInfo = (locationId, bannerId, data) =>
  axiosClient({
    method: "PUT",
    url: API_UPDATE_BANNER_INFO(locationId, bannerId),
    data,
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const deleteBannerInfo = (locationId, bannerId) =>
  axiosClient({
    method: "DELETE",
    url: API_DELETE_BANNER_INFO(locationId, bannerId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const fetchApiKeyInfo = (locationId) =>
  axiosClient({
    method: "GET",
    url: API_GET_API_KEY_INFO(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const createNewApiKey = (locationId, data) =>
  axiosClient({
    method: "POST",
    data,
    url: API_CREATE_API_KEY_INFO(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const updateApiKey = (locationId, data) =>
  axiosClient({
    method: "PUT",
    data,
    url: API_UPDATE_API_KEY_INFO(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const fetchAllMessageInfo = (locationId) =>
  axiosClient({
    method: "GET",
    url: API_GET_MESSAGE_INFO(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const createNewMessageInfo = (locationId, data) =>
  axiosClient({
    method: "POST",
    url: API_CREATE_MESSAGE_INFO(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
    data,
  });

export const sendNotification = (locationId, messageId) =>
  axiosClient({
    method: "GET",
    url: API_SEND_NOTIFICATION(locationId, messageId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });


export const updateMessageInfo = (locationId, messageId, data) =>
  axiosClient({
    method: "PUT",
    url: API_UPDATE_MESSAGE_INFO(locationId, messageId),
    data,
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });
