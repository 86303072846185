/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import { Grid, Typography, useTheme } from "@material-ui/core";
import { useRouteMatch } from "react-router-dom";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import StorefrontIcon from "@material-ui/icons/Storefront";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import DashboardIcon from "@material-ui/icons/Dashboard";
import BookmarkBorder from "@material-ui/icons/BookmarkBorder";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import { Icon } from "@iconify/react";
import ColorLensIcon from "@material-ui/icons/ColorLens";
import ArtTrackIcon from "@material-ui/icons/ArtTrack";
import ListAltIcon from "@material-ui/icons/ListAlt";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import { getLocationBasicInfo } from "../../../services/locationApp/storeService";
import { CustomTheme } from "../../../types/customTheme";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import MessageIcon from "@material-ui/icons/Message";

export interface PageHeaderProps {}

/* This component displays the header for each page. */
const PageHeader: React.FunctionComponent<PageHeaderProps> = () => {
  const [locationData, setLocationData] = useState<any>();

  const match: any = useRouteMatch();

  const fetchBasicInfo = async () => {
    try {
      const res = await getLocationBasicInfo(match.params.locationId);
      setLocationData(res.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchBasicInfo();
  }, [document.title]);

  const handleIcons = (title: string) => {
    if (title === " Dashboard") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "36px" }}
        >
          Dashboard
        </span>
      );
    }
    if (
      window.location.href.includes(
        "/internalConfiguration/paymentConfiguration",
      )
    ) {
      return <Icon icon="dashicons:admin-generic" height="40" width="40" />;
    }
    if (
      window.location.href.includes("/internalConfiguration/users-permission")
    ) {
      return <VerifiedUserIcon style={{ fontSize: "36px" }} />;
    }
    if (title === " Sale Information") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "36px" }}
        >
          list_alt
        </span>
      );
    }
    if (title === " Shift Summary") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "36px" }}
        >
          description
        </span>
      );
    }
    if (title === " Item Sales") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "36px" }}
        >
          format_list_numbered
        </span>
      );
    }
    if (title === " Category Sales") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "36px" }}
        >
          view_list
        </span>
      );
    }
    if (title === " Revenue Center Report") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "36px" }}
        >
          hub
        </span>
      );
    }
    if (title === " Daily Sales Report") {
      return <DateRangeOutlinedIcon style={{ fontSize: "36px" }} />;
    }
    if (title === " Cashier Summary Report") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "40px" }}
        >
          user_attributes
        </span>
      );
    }
    if (title === " Discount Summary Report") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "44px" }}
        >
          heap_snapshot_thumbnail
        </span>
      );
    }
    if (title === " Sales By Cashier Report") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "44px" }}
        >
          recent_actors
        </span>
      );
    }
    if (title === " Driver Sessions Summary") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "44px" }}
        >
          pedal_bike
        </span>
      );
    }
    if (title === " Pay In Out") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "36px" }}
        >
          swap_horiz
        </span>
      );
    }
    if (title === " Payment Report") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "36px" }}
        >
          wallet
        </span>
      );
    }
    if (title === " VAT Summary") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "36px" }}
        >
          assured_workload
        </span>
      );
    }
    if (title === " Sale Report") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "36px" }}
        >
          receipt_long
        </span>
      );
    }
    if (title === " Sale Cancel") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "36px" }}
        >
          cancel
        </span>
      );
    }
    if (title === " Void Transaction") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "36px" }}
        >
          delete_forever
        </span>
      );
    }
    if (window.location.href.includes("/presto-locations/basic-info")) {
      return <StorefrontIcon style={{ fontSize: "36px" }} />;
    }
    if (
      window.location.href.includes(
        "/presto-locations/internalConfiguration/scheduledTask",
      )
    ) {
      return <AssignmentTurnedInIcon style={{ fontSize: "40px" }} />;
    }
    if (window.location.href.includes("/presto-locations/opening-times")) {
      return <AccessTimeIcon style={{ fontSize: "36px" }} />;
    }
    if (window.location.href.includes("/presto-locations")) {
      return <DashboardIcon style={{ fontSize: "36px" }} />;
    }
    if (window.location.href.includes("/eatpresto/orders")) {
      return <BookmarkBorder style={{ fontSize: "36px" }} />;
    }
    if (window.location.href.includes("/eatpresto/discounts")) {
      return <Icon icon="tabler:discount-2" height="36" width="36" />;
    }
    if (window.location.href.includes("/eatpresto/delivery-info")) {
      return <LocalShippingIcon style={{ fontSize: "36px" }} />;
    }
    if (window.location.href.includes("/eatpresto/theme")) {
      return <ColorLensIcon style={{ fontSize: "36px" }} />;
    }
    if (window.location.href.includes("/eatpresto/banners")) {
      return <ArtTrackIcon style={{ fontSize: "36px" }} />;
    }
    if (window.location.href.includes("/eatpresto/closureDates")) {
      return <QueryBuilderIcon style={{ fontSize: "36px" }} />;
    }
    if (window.location.href.includes("/eatpresto/dailyOrdersReport")) {
      return <Icon height="36" width="36" icon="carbon:report" />;
    }
    if (window.location.href.includes("/eatpresto")) {
      return <DashboardIcon style={{ fontSize: "36px" }} />;
    }
    if (window.location.href.includes("/presto-task/task")) {
      return <ListAltIcon style={{ fontSize: "36px" }} />;
    }
    if (window.location.href.includes("/presto-task")) {
      return <DashboardIcon style={{ fontSize: "36px" }} />;
    }
    if (window.location.href.includes("/presto-menus/menu")) {
      return <MenuBookIcon style={{ fontSize: "36px" }} />;
    }
    if (window.location.href.includes("/presto-menus/printerMapping")) {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "36px" }}
        >
          print
        </span>
      );
    }
    if (window.location.href.includes("/presto-menus/mappingStrategies")) {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "36px" }}
        >
          alt_route
        </span>
      );
    }
    if (
      window.location.href.includes("/presto-menus") &&
      !window.location.href.includes(
        "/presto-menus/internalConfiguration/productConfiguration",
      )
    ) {
      return <DashboardIcon style={{ fontSize: "36px" }} />;
    }
    if (window.location.href.includes("/stockItems")) {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "36px" }}
        >
          inventory_2
        </span>
      );
    }
    if (window.location.href.includes("admin/group")) {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "36px" }}
        >
          hive
        </span>
      );
    }
    if (window.location.href.includes("admin/department")) {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "36px" }}
        >
          token
        </span>
      );
    }
    if (window.location.href.includes("admin/supplier")) {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "36px" }}
        >
          local_shipping
        </span>
      );
    }
    if (window.location.href.includes("/stockOrders")) {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "36px" }}
        >
          draft_orders
        </span>
      );
    }
    if (window.location.href.includes("/stockMovements")) {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "36px" }}
        >
          swap_horiz
        </span>
      );
    }
    if (window.location.href.includes("/waste")) {
      return <Icon icon="ri:filter-fill" height="28" width="28" />;
    }

    if (window.location.href.includes("/stockTake")) {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "36px" }}
        >
          inventory
        </span>
      );
    }

    if (
      window.location.href.includes("/internalConfiguration/stockConfiguration")
    ) {
      return <Icon icon="dashicons:admin-generic" height="40" width="40" />;
    }

    if (
      window.location.href.includes(
        "/internalConfiguration/loyaltyConfiguration",
      )
    ) {
      return <Icon icon="dashicons:admin-generic" height="40" width="40" />;
    }
    if (
      window.location.href.includes(
        "/internalConfiguration/apiKeyConfiguration",
      )
    ) {
      return <LockOpenIcon style={{ fontSize: "36px" }} />;
    }

    if (
      window.location.href.includes(
        "/internalConfiguration/productConfiguration",
      )
    ) {
      return <Icon icon="dashicons:admin-generic" height="40" width="40" />;
    }

    if (window.location.href.includes("/presto-customer/customerList")) {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "36px" }}
        >
          group
        </span>
      );
    }

    if (window.location.href.includes("/presto-customer/strategyList")) {
      return <Icon icon="material-symbols:strategy" height="36" width="36" />;
    }

    if (window.location.href.includes("/presto-customer/rewardList")) {
      return (
        <Icon
          icon="material-symbols-light:rewarded-ads"
          height="44"
          width="44"
        />
      );
    }

    if (window.location.href.includes("/presto-customer/bannerList")) {
      return (
        <Icon
          icon="material-symbols:planner-banner-ad-pt"
          height="36"
          width="36"
        />
      );
    }

    if (window.location.href.includes("/presto-customer/messages")) {
      return <MessageIcon style={{ fontSize: "36px" }} />;
    }
  };
  const handleTitle = (title: string) => {
    if (title === " Sale Information") {
      return "Sale Transactions";
    } else if (
      window.location.href.includes(
        "/internalConfiguration/paymentConfiguration",
      )
    ) {
      return "Payment Configuration";
    }
    if (
      window.location.href.includes("/internalConfiguration/users-permission")
    ) {
      return "Users & Permissions";
    }
    if (title === " Pay In Out") {
      return "Paid In-Out Report";
    }
    if (title === " Payment Report") {
      return "Payment Types Report";
    }
    if (title === " VAT Summary") {
      return "VAT Summary Report";
    }
    if (title === " Sale Report") {
      return "Sale Summary Report";
    }
    if (title === " Sale Cancel") {
      return "Cancelled Sales Transactions";
    }
    if (title === " Void Transaction") {
      return "Voided Transactions";
    }
    if (window.location.href.includes("/presto-locations/opening-times")) {
      return "Opening Times";
    }
    if (window.location.href.includes("/presto-locations/basic-info")) {
      return "Business Info";
    }
    if (
      window.location.href.includes(
        "/presto-locations/internalConfiguration/scheduledTask",
      )
    ) {
      return "Scheduled Task";
    }
    if (window.location.href.includes("/presto-locations")) {
      return "Dashboard";
    }
    if (window.location.href.includes("/eatpresto/orders")) {
      return "Eat Presto Online Orders";
    }
    if (window.location.href.includes("/eatpresto/discounts")) {
      return "Discounts";
    }
    if (window.location.href.includes("/eatpresto/delivery-info")) {
      return "Collection, Delivery & Dine-In Information";
    }
    if (window.location.href.includes("/eatpresto/theme")) {
      return "Online Ordering Theme";
    }
    if (window.location.href.includes("/eatpresto/banners")) {
      return "Banners";
    }
    if (window.location.href.includes("/eatpresto/closureDates")) {
      return "Closure Dates";
    }
    if (window.location.href.includes("/eatpresto/dailyOrdersReport")) {
      return "Daily Orders Report";
    }
    if (window.location.href.includes("/eatpresto")) {
      return "Dashboard";
    }
    if (window.location.href.includes("/presto-task/task")) {
      return "Task Information";
    }
    if (window.location.href.includes("/presto-task")) {
      return "Dashboard";
    }
    if (window.location.href.includes("/presto-menus/menu")) {
      return "Menu Information";
    }
    if (window.location.href.includes("/presto-menus/printerMapping")) {
      return "Menu Printer Information";
    }
    if (window.location.href.includes("/presto-menus/mappingStrategies")) {
      return "Mapping Strategy Information";
    }

    if (
      window.location.href.includes("/presto-menus") &&
      !window.location.href.includes("/presto-menus/printerMapping") &&
      !window.location.href.includes("/presto-menus/mappingStrategies") &&
      !window.location.href.includes(
        "/presto-menus/internalConfiguration/productConfiguration",
      )
    ) {
      return "Dashboard";
    } else if (window.location.href.includes("/stockItems")) {
      return "Stock Items";
    } else if (window.location.href.includes("/admin/group")) {
      return "Groups";
    } else if (window.location.href.includes("/admin/department")) {
      return "Departments";
    } else if (window.location.href.includes("/admin/supplier")) {
      return "Suppliers";
    } else if (window.location.href.includes("/stockOrders")) {
      return "Stock Orders";
    } else if (window.location.href.includes("/stockMovements")) {
      return "Stock Movements Report";
    } else if (window.location.href.includes("/stockTake")) {
      return "Stock Take";
    } else if (window.location.href.includes("/waste")) {
      return "Waste";
    } else if (
      window.location.href.includes(
        "/internalConfiguration/loyaltyConfiguration",
      )
    ) {
      return "Loyalty Configuration";
    } else if (
      window.location.href.includes(
        "/internalConfiguration/apiKeyConfiguration",
      )
    ) {
      return "API Key Configuration";
    } else if (
      window.location.href.includes("/internalConfiguration/stockConfiguration")
    ) {
      return "Stock Configuration";
    } else if (
      window.location.href.includes(
        "/internalConfiguration/productConfiguration",
      )
    ) {
      return "Product Configuration";
    } else if (window.location.href.includes("/presto-customer/customerList")) {
      return "Customer List";
    } else if (window.location.href.includes("/presto-customer/strategyList")) {
      return "Loyalty Strategies";
    } else if (window.location.href.includes("/presto-customer/rewardList")) {
      return "Loyalty Rewards";
    } else if (window.location.href.includes("/presto-customer/bannerList")) {
      return "Loyalty Banners";
    } else if (window.location.href.includes("/presto-customer/messages")) {
      return "Push Notifications";
    }
    return title;
  };

  const handleSubTitle = (app: string, title: string) => {
    if (app === "Sale " && title === " Dashboard") {
      return `Sale Overview-${locationData?.businessDisplayName}`;
    } else if (
      window.location.href.includes(
        "/internalConfiguration/paymentConfiguration",
      )
    ) {
      return `Payment Configuration-${locationData?.businessDisplayName}`;
    }
    if (
      window.location.href.includes("/internalConfiguration/users-permission")
    ) {
      return `Users & Permissions-${locationData?.businessDisplayName}`;
    }
    if (title === " Sale Information") {
      return `Browse sales transactions-${locationData?.businessDisplayName}`;
    }
    if (title === " Shift Summary") {
      return `Browse shift reports-${locationData?.businessDisplayName}`;
    }
    if (title === " Item Sales") {
      return `Generate itemized sales report-${locationData?.businessDisplayName}`;
    }
    if (title === " Category Sales") {
      return `Generate itemized sales report-${locationData?.businessDisplayName}`;
    }
    if (title === " Daily Sales Report") {
      return `Generate Daily Sales report-${locationData?.businessDisplayName}`;
    }
    if (title === " Cashier Summary Report") {
      return `Generate Cashier Summary report-${locationData?.businessDisplayName}`;
    }
    if (title === " Discount Summary Report") {
      return `Generate Discount Summary report-${locationData?.businessDisplayName}`;
    }
    if (title === " Sales By Cashier Report") {
      return `Generate Sales By Cashier report-${locationData?.businessDisplayName}`;
    }
    if (title === " Driver Sessions Summary") {
      return `Generate  Driver Sessions report-${locationData?.businessDisplayName}`;
    }
    if (title === " Driver Sessions Summary") {
      return `Generate Driver Sessions report-${locationData?.businessDisplayName}`;
    }
    if (title === " Revenue Center Report") {
      return `Generate Revenue Center report-${locationData?.businessDisplayName}`;
    }
    if (title === " Pay In Out") {
      return `Generate paid in-out report-${locationData?.businessDisplayName}`;
    }
    if (title === " Payment Report") {
      return `Generate payment types report-${locationData?.businessDisplayName}`;
    }
    if (title === " VAT Summary") {
      return `Generate VAT summary report-${locationData?.businessDisplayName}`;
    }
    if (title === " Sale Report") {
      return `Generate Sale summary report-${locationData?.businessDisplayName}`;
    }
    if (title === " Sale Cancel") {
      return `Browse cancelled sales transactions-${locationData?.businessDisplayName}`;
    }
    if (title === " Void Transaction") {
      return `Browse voided transactions-${locationData?.businessDisplayName}`;
    }
    if (
      app === "Locations " &&
      window.location.href.includes("/presto-locations/basic-info")
    ) {
      return `Manage business information-${locationData?.businessDisplayName}`;
    }
    if (
      app === "Locations " &&
      window.location.href.includes(
        "/presto-locations/internalConfiguration/scheduledTask",
      )
    ) {
      return `Manage Scheduled Tasks-${locationData?.businessDisplayName}`;
    }
    if (
      app === "Locations " &&
      window.location.href.includes("/presto-locations/opening-times")
    ) {
      return `Manage opening times-${locationData?.businessDisplayName}`;
    }
    if (
      app === "Locations " &&
      window.location.href.includes("/presto-locations")
    ) {
      return `Location Overview-${locationData?.businessDisplayName}`;
    }
    if (window.location.href.includes("/eatpresto/orders")) {
      return `Browse online orders-${locationData?.businessDisplayName}`;
    }
    if (window.location.href.includes("/eatpresto/discounts")) {
      return `Manage discounts-${locationData?.businessDisplayName}`;
    }
    if (window.location.href.includes("/eatpresto/delivery-info")) {
      return `Manage collection, delivery & dine-in information-${locationData?.businessDisplayName}`;
    }
    if (window.location.href.includes("/eatpresto/theme")) {
      return `Manage theme colours-${locationData?.businessDisplayName}`;
    }
    if (window.location.href.includes("/eatpresto/banners")) {
      return `Manage promo and information banners-${locationData?.businessDisplayName}`;
    }
    if (window.location.href.includes("/eatpresto/closureDates")) {
      return `Manage irregular business closure dates-${locationData?.businessDisplayName}`;
    }
    if (window.location.href.includes("/eatpresto/dailyOrdersReport")) {
      return `Daily Orders Report-${locationData?.businessDisplayName}`;
    }
    if (window.location.href.includes("/eatpresto")) {
      return `Eat Presto online ordering-${locationData?.businessDisplayName}`;
    }
    if (window.location.href.includes("/presto-task/task")) {
      return `Eat Presto online ordering-${locationData?.businessDisplayName}`;
    }
    if (window.location.href.includes("/presto-task")) {
      return `Task Overview-${locationData?.businessDisplayName}`;
    }
    if (window.location.href.includes("/presto-menus/menu")) {
      return `Menu Overview-${locationData?.businessDisplayName}`;
    }
    if (window.location.href.includes("/presto-menus/printerMapping")) {
      return `Menu Printer Overview-${locationData?.businessDisplayName}`;
    }
    if (window.location.href.includes("/presto-menus/mappingStrategies")) {
      return `Mapping Strategy Overview-${locationData?.businessDisplayName}`;
    }
    if (
      window.location.href.includes("/presto-menus") &&
      !window.location.href.includes("/presto-menus/printerMapping") &&
      !window.location.href.includes("/presto-menus/mappingStrategies") &&
      !window.location.href.includes(
        "/presto-menus/internalConfiguration/productConfiguration",
      )
    ) {
      return `Menu Overview-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/stockItems")) {
      return `Stock Items Overview-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/stockOrders")) {
      return `Stock Order Overview-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/swap_horiz")) {
      return `Stock Movements Report Overview-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/admin/group")) {
      return `Groups Overview-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/admin/department")) {
      return `Departments Overview-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/admin/supplier")) {
      return `Suppliers Overview-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/stockTake")) {
      return `Stock Take Overview-${locationData?.businessDisplayName}`;
    } else if (
      window.location.href.includes("/internalConfiguration/stockConfiguration")
    ) {
      return `Stock Configuration-${locationData?.businessDisplayName}`;
    } else if (
      window.location.href.includes(
        "/internalConfiguration/loyaltyConfiguration",
      )
    ) {
      return `Loyalty Configuration-${locationData?.businessDisplayName}`;
    } else if (
      window.location.href.includes(
        "/internalConfiguration/apiKeyConfiguration",
      )
    ) {
      return `API Key Configuration-${locationData?.businessDisplayName}`;
    } else if (
      window.location.href.includes(
        "/internalConfiguration/productConfiguration",
      )
    ) {
      return `Product Configuration-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/presto-customer/customerList")) {
      return `Customer List-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/presto-customer/strategyList")) {
      return `Loyalty Strategies-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/presto-customer/rewardList")) {
      return `Loyalty Rewards-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/presto-customer/bannerList")) {
      return `Loyalty Banners-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/presto-customer/messages")) {
      return `Push Notifications-${locationData?.businessDisplayName}`;
    }
  };
  const theme: CustomTheme = useTheme();
  return (
    <Grid container>
      <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
        <Typography
          style={{
            color: theme.palette.custom.orange.contrastText,
            marginTop: "2px",
          }}
        >
          {handleIcons(document.title.split("-")[1])}
        </Typography>
        <div style={{ marginLeft: "8px" }}>
          <div>
            <Typography
              align="left"
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                display: "flex",
                color: theme.palette.custom.orange.contrastText,
                lineHeight: "20px",
              }}
            >
              {handleTitle(document.title.split("-")[1])}
            </Typography>
          </div>
          <div
            style={{
              color: theme.palette.custom.orange.contrastText,
              fontSize: "10px",
              display: "flex",
              fontWeight: "lighter",
            }}
          >
            {handleSubTitle(
              document.title.split("-")[0],
              document.title.split("-")[1],
            )}
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default PageHeader;
