import React, { useEffect, useState } from "react";
import { CustomTheme } from "../../../../types/customTheme";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  createStyles,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

export interface MessagePreviewProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  messageDetails: any;
}

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    root: {
      "& .MuiDialog-paper": {
        width: 280, // Custom width of 220px
      },
    },
  }),
);

const MessagePreview: React.FunctionComponent<MessagePreviewProps> = ({
  isOpen,
  setIsOpen,
  messageDetails,
}) => {
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [message, setMessage] = useState("");
  const [img, setImg] = useState("");

  useEffect(() => {
    if (Object.values(messageDetails).length > 0) {
      setTitle(messageDetails.title);
      setSubTitle(messageDetails.subtitle);
      setMessage(messageDetails.message);
      setImg(messageDetails.img);
    }
  }, [messageDetails, isOpen]);

  const theme: CustomTheme = useTheme();
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      fullWidth
      className={classes.root}
    >
      <DialogContent style={{ padding: 0 }}>
        <Card style={{ borderRadius: 2 }}>
          {/* Header */}
          <Grid
            container
            style={{
              backgroundColor:
                theme.palette.background.entity_highlight_background,
              paddingTop: "12px",
              paddingBottom: "2px",
            }}
          >
            <Grid item xs={1}>
              <Typography
                style={{
                  padding: "5px 0px 8px 8px",
                  cursor: "pointer",
                }}
                onClick={() => setIsOpen(false)}
              >
                <ArrowBackIosIcon style={{ fontSize: "16px" }} />
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography
                variant="body1"
                style={{
                  padding: "2px 2px 2px 4px",
                  fontWeight: "bold",
                }}
              >
                {title}
              </Typography>
            </Grid>
          </Grid>

         {img&& <Box
            style={{
              background: `linear-gradient(to bottom, ${theme.palette.background.entity_highlight_background} 20%, white 20%)`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "12px 20px 0px 20px",
            }}
          >
            {/* Image */}
            <CardMedia
              component="img"
              image={img}
              alt=""
              style={{
                borderRadius: "10px",
                width: "264px",
                height: "220px",
              }}
            />
          </Box>}
          {/* Message Content */}
          <CardContent style={{ backgroundColor: "white" }}>
            <Typography
              variant="body1"
              component="div"
              style={{ color: "black", fontWeight: "bold" }}
            >
              {subTitle}
            </Typography>

            <Divider
              style={{
                margin: "12px 0",
                borderTop: `2px dashed ${theme.palette.background.entity_highlight_background}`, // Ensures the dashed effect is visible
                borderBottom: "none",
              }}
            />

            <Typography
              variant="body2"
              style={{ color: "black", whiteSpace: "pre-line" }}
            >
              {message}
            </Typography>
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default MessagePreview;
