import React from "react";
import { Route, Switch } from "react-router-dom";
import Authorities from "../../../auth/authorities";
import { getIsAuthorized } from "../../../utils/authorities";
import CustomerListPage from "../pages/CustomerListPage";
import { Icon } from "@iconify/react";
import Location from "../../PrestoExpressLocationApp/pages/Location";
import LoyaltyConfiguration from "../pages/LoyaltyConfiguration";
import StrategyPage from "../pages/StrategyPage";
import RewardListPage from "../pages/RewardListPage";
import BannerListPage from "../pages/BannerListPage";
import ApiKeyPage from "../pages/ApiKeyPage";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import MessageIcon from '@material-ui/icons/Message';
import MessageListPage from "../pages/MessageListPage";

export const getSidebarRoutes = () => {
  const sidebarRoute = [
    {
      id: "CustomerList",
      title: "Customer List",
      path: "/customerList",
      icon: <span className="material-symbols-outlined">group</span>,
      isAuthorized: getIsAuthorized(Authorities.CUSTOMER_LIST_READ),
    },
    {
      id: "StrategyList",
      title: "Loyalty Strategies",
      path: "/strategyList",
      icon: <Icon icon="material-symbols:strategy" height="26" width="26" />,
      isAuthorized: getIsAuthorized(Authorities.CUSTOMER_LIST_READ),
    },

    {
      id: "RewardList",
      title: "Loyalty Rewards",
      path: "/rewardList",
      icon: (
        <Icon
          icon="material-symbols-light:rewarded-ads"
          height="26"
          width="26"
        />
      ),
      isAuthorized: getIsAuthorized(Authorities.CUSTOMER_LIST_READ),
    },
    {
      id: "BannerList",
      title: "Loyalty Banners",
      path: "/bannerList",
      icon: (
        <Icon
          icon="material-symbols:planner-banner-ad-pt"
          height="26"
          width="26"
        />
      ),
      isAuthorized: getIsAuthorized(Authorities.CUSTOMER_LIST_READ),
    },
    {
      id: "messagesList",
      title: "Push Notifications",
      path: "/messages",
      icon: (
        <MessageIcon />
      ),
      isAuthorized: getIsAuthorized(Authorities.CUSTOMER_LIST_READ),
    },
    {
      id: "internal",
      title: "Internal Configurations",
      path: "/internalConfiguration",
      icon: <Icon icon="ri:admin-line" height="24" width="24" />,
      isAuthorized: getIsAuthorized(Authorities.STOCK_CONFIGURATION_READ),
      childRoutes: [
        {
          id: "loyaltyConfiguration",
          title: "Loyalty Configuration",
          path: "/internalConfiguration/loyaltyConfiguration",
          icon: <Icon icon="dashicons:admin-generic" height="24" width="24" />,
          isAuthorized: getIsAuthorized(Authorities.STOCK_CONFIGURATION_READ),
        },
        {
          id: "apiKeyConfiguration",
          title: "API Key Configuration",
          path: "/internalConfiguration/apiKeyConfiguration",
          icon: <LockOpenIcon />,
          isAuthorized: getIsAuthorized(Authorities.STOCK_CONFIGURATION_READ),
        },
      ],
    },
  ];
  return sidebarRoute;
};
export interface TasksAppRoutesProps {}

const TasksAppRoutes: React.FunctionComponent<TasksAppRoutesProps> = () => {
  return (
    <Switch>
      <Route
        path="/:locationId/presto-customer/customerList"
        component={CustomerListPage}
        exact
      />
      <Route
        path="/:locationId/presto-customer/internalConfiguration/loyaltyConfiguration"
        component={LoyaltyConfiguration}
        exact
      />
      <Route
        path="/:locationId/presto-customer/strategyList"
        component={StrategyPage}
        exact
      />
      <Route
        path="/:locationId/presto-customer/rewardList"
        component={RewardListPage}
        exact
      />
      <Route
        path="/:locationId/presto-customer/bannerList"
        component={BannerListPage}
        exact
      />
 <Route
        path="/:locationId/presto-customer/messages"
        component={MessageListPage}
        exact
      />

      <Route
        path="/:locationId/presto-customer/internalConfiguration/apiKeyConfiguration"
        component={ApiKeyPage}
        exact
      />

      <Route path="/" component={Location} exact />
    </Switch>
  );
};

export default TasksAppRoutes;
