import React from "react";
import _ from "lodash";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  createStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import "jspdf-autotable";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import { CustomTheme } from "../../../../types/customTheme";
import DialogCommonDefault from "../../../../components/dialogs/DialogCommonDefault";
import TextfieldDefaultNew from "../../../../components/textField/TextfieldDefaultNew";
import ValidationMessage from "../../../../components/validation/ValidationMessage";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import {
  DatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";

export interface CustomerNodeProps {
  isOpen: any;
  setIsOpen: any;
  isEdit: any;
  handleReset: any;
  isLoadingButton: any;
  handleSubmitData: any;
  title: any;
  subTitle: any;
  message: any;
  img: any;
  isError: any;
  setIsError: any;
  setTitle: any;
  setSubTitle: any;
  setMessage: any;
  setImg: any;
  actionUrl: any;
  setActionUrl: any;
  selectedDate: any;
  setSelectedDate: any;
  handleSendPublishNotification: any;
  isLoadingSubmitButton: any;
  titleInitial: any;
  subTitleInitial: any;
  messageInitial: any;
  imgInitial: any;
  selectedDateInitial: any;
  actionUrlInitial: any;
  status: any;
}

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    datePickerStyle: {
      [`& fieldset`]: {
        borderRadius: "10px",
        border: `1px solid ${theme.palette.background.entity_dialog_textfield_background}`,
        cursor: "pointer",
      },
      "& .MuiPickersClockPointer-pointer": {
        backgroundColor: `${theme.palette.background.default} !important`,
      },
      "& .MuiPickersClockNumber-clockNumberSelected": {
        backgroundColor: `${theme.palette.background.default} !important`,
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.background.entity_border}`,
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.background.entity_border}`,
      },
    },
  }),
);

const MessageCreateAndUpdateModal: React.FunctionComponent<
  CustomerNodeProps
> = ({
  isOpen,
  setIsOpen,
  isEdit,
  handleReset,
  isLoadingButton,
  handleSubmitData,
  title,
  subTitle,
  message,
  img,
  isError,
  setIsError,
  setTitle,
  setSubTitle,
  setMessage,
  setImg,
  actionUrl,
  setActionUrl,
  selectedDate,
  setSelectedDate,
  handleSendPublishNotification,
  isLoadingSubmitButton,
  titleInitial,
  subTitleInitial,
  messageInitial,
  imgInitial,
  selectedDateInitial,
  actionUrlInitial,
  status,
}) => {
  const handleDisable = () => {
    if (isEdit && status === "sent") {
      return true;
    } else {
      return false;
    }
    // if (
    //   titleInitial !== title ||
    //   subTitleInitial !== subTitle ||
    //   message !== messageInitial ||
    //   img !== imgInitial ||
    //   actionUrl !== actionUrlInitial
    //   // moment(selectedDate).format("YYYY-MM-DD") !==
    //   //   moment(selectedDateInitial).format("YYYY-MM-DD") ||
    // ) {
    //   return false;
    // } else {
    //   return true;
    // }
  };
  const theme: CustomTheme = useTheme();
  const classes = useStyles();
  return (
    <>
      <DialogCommonDefault
        open={isOpen}
        setOpen={setIsOpen}
        isNeedFixedHeight={false}
        maxWidth={"md"}
      >
        <DialogTitle>Add New Message</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={title || subTitle || message || img ? 8 : 12}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: "bold" }}>Title</Typography>
                  <div style={{ display: "flex" }}>
                    <TextfieldDefaultNew
                      id="title"
                      name="title"
                      type="text"
                      value={title}
                      disabled={isEdit && status === "sent"}
                      onChange={(e: any) => {
                        setTitle(e.target.value);
                        setIsError(false);
                      }}
                    />
                  </div>
                  {!title && isError ? (
                    <ValidationMessage message={"Title is required"} />
                  ) : (
                    ""
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Typography style={{ fontWeight: "bold" }}>
                    Sub Title
                  </Typography>
                  <div style={{ display: "flex" }}>
                    <TextfieldDefaultNew
                      id="subTitle"
                      name="subTitle"
                      type="text"
                      disabled={isEdit && status === "sent"}
                      value={subTitle}
                      onChange={(e: any) => setSubTitle(e.target.value)}
                    />
                  </div>
                  {!subTitle && isError ? (
                    <ValidationMessage message={"Sub Title is required"} />
                  ) : (
                    ""
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Typography style={{ fontWeight: "bold" }}>
                    Message
                  </Typography>
                  <div style={{ display: "flex" }}>
                    <TextfieldDefaultNew
                      id="message"
                      name="message"
                      type="text"
                      multiline={true}
                      rows={5}
                      disabled={isEdit && status === "sent"}
                      value={message}
                      onChange={(e: any) => setMessage(e.target.value)}
                    />
                  </div>
                  {!message && isError ? (
                    <ValidationMessage message={"Message  is required"} />
                  ) : (
                    ""
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Typography style={{ fontWeight: "bold" }}>Img</Typography>
                  <div style={{ display: "flex" }}>
                    <TextfieldDefaultNew
                      id="img"
                      name="img"
                      disabled={isEdit && status === "sent"}
                      type="text"
                      value={img}
                      onChange={(e: any) => setImg(e.target.value)}
                    />
                  </div>
                  {!img.includes("http") && img && isError ? (
                    <ValidationMessage message={"Invalid Img"} />
                  ) : (
                    ""
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Typography style={{ fontWeight: "bold" }}>
                    Action URL
                  </Typography>
                  <div style={{ display: "flex" }}>
                    <TextfieldDefaultNew
                      id="actionUrl"
                      name="actionUrl"
                      disabled={isEdit && status === "sent"}
                      type="text"
                      value={actionUrl}
                      onChange={(e: any) => setActionUrl(e.target.value)}
                    />
                  </div>
                </Grid>

                {/* <Grid item xs={12}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "8px" }}
                  >
                    Expire
                  </Typography>
                  <div style={{ display: "flex" }}>
                    <KeyboardDatePicker
                      autoOk
                      margin="none"
                      label=""
                      format="MM/dd/yyyy"
                      value={selectedDate}
                      onChange={(newDate) => setSelectedDate(newDate)}
                      KeyboardButtonProps={{ "aria-label": "change date" }}
                      variant="inline"
                      inputVariant="outlined"
                      // minDate={isEdit?new Date("1990:03:03"):new Date()}
                      className={classes.datePickerStyle}
                      fullWidth
                      InputProps={{
                        style: {
                          borderRadius: 10,
                          backgroundColor:
                            theme.palette.background
                              .entity_dialog_textfield_background,
                        },
                      }}
                    />
                  </div>
                </Grid> */}
              </Grid>{" "}
            </Grid>
            {/* Phone Simulator */}
            {(title || subTitle || message || img) && (
              <Grid item xs={12} sm={4}>
                <Card
                  style={{
                    overflowY: "auto",
                    WebkitOverflowScrolling: "touch",
                    scrollbarWidth: "thin",
                    height: "70vh",
                    boxShadow: "none",
                    border: `1px solid ${theme.palette.background.entity_border}`,
                    borderRadius: "10px", // Ensure the border radius is applied
                    boxSizing: "border-box",
                  }}
                >
                  <div
                    style={{
                      height: "100%",
                      background: `linear-gradient(to bottom, ${theme.palette.background.entity_highlight_background} 10%, white 10%)`,
                    }}
                  >
                    <Grid
                      container
                      style={{
                        backgroundColor:
                          theme.palette.background.entity_highlight_background,
                      }}
                    >
                      <Grid item xs={1}>
                        <Typography
                          style={{
                            padding: "5px 0px 8px 8px",
                            cursor: "pointer",
                          }}
                          onClick={() => setIsOpen(false)}
                        >
                          <ArrowBackIosIcon style={{ fontSize: "16px" }} />
                        </Typography>
                      </Grid>
                      {title && (
                        <Grid item xs={10}>
                          <Typography
                            style={{
                              padding: "2px 2px 2px 4px",
                              fontWeight: "bold",
                              fontSize: "18px",
                            }}
                          >
                            {title}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                    <div style={{ backgroundColor: "white" }}>
                      {img && (
                        <Box
                          style={{
                            background: `linear-gradient(to bottom, ${theme.palette.background.entity_highlight_background} 20%, white 20%)`,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "12px 12px 0px 12px",
                          }}
                        >
                          <CardMedia
                            component="img"
                            image={img}
                            alt=""
                            style={{
                              borderRadius: "10px",
                              height: "220px",
                              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                            }}
                          />
                        </Box>
                      )}
                      <CardContent style={{ backgroundColor: "white" }}>
                        {subTitle && (
                          <Typography
                            variant="body1"
                            component="div"
                            style={{ color: "black", fontWeight: "bold" }}
                          >
                            {subTitle}
                          </Typography>
                        )}

                        {subTitle && message && (
                          <Divider
                            style={{
                              margin: "12px 0",
                              borderTop: `2px dashed ${theme.palette.background.entity_highlight_background}`,
                              borderBottom: "none",
                            }}
                          />
                        )}

                        <Typography
                          variant="body2"
                          style={{ color: "black", whiteSpace: "pre-line" }}
                        >
                          {message}
                        </Typography>
                      </CardContent>
                    </div>
                  </div>
                </Card>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginBottom: "8px" }}>
          <ButtonCommon
            variant="contained"
            style={{ fontSize: 11, width: "100px", marginRight: 8 }}
            color="yellow"
            onClick={handleReset}
          >
            Cancel
          </ButtonCommon>
          <ButtonCommon
            variant="contained"
            style={{
              fontSize: 11,
              marginLeft: 4,
              width: "120px",
            }}
            disabled={handleDisable()}
            isLoadingPage={isLoadingSubmitButton}
            color={"orange"}
            onClick={handleSubmitData}
          >
            {"Save Draft"}
          </ButtonCommon>
          <ButtonCommon
            variant="contained"
            style={{
              fontSize: 11,
              marginLeft: 12,
              width: "100px",
            }}
            disabled={isEdit && status === "sent"}
            isLoadingPage={false}
            color={"green"}
            onClick={handleSendPublishNotification}
          >
            {"Publish"}
          </ButtonCommon>
        </DialogActions>
      </DialogCommonDefault>
    </>
  );
};

export default MessageCreateAndUpdateModal;
